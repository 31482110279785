import React from "react";

const SendProjectIcon = () => (
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g id='Send'>
      <g id='Application/Send' clipPath='url(#clip0_1584_31446)'>
        <path
          id='Vector'
          d='M27.2348 15.6535L4.82854 4.41954C4.73747 4.374 4.633 4.36329 4.53389 4.3874C4.4239 4.41459 4.32916 4.48426 4.27042 4.58115C4.21169 4.67804 4.19374 4.79426 4.2205 4.90436L6.52943 14.3383C6.56425 14.4803 6.66872 14.5954 6.808 14.641L10.7643 15.999L6.81068 17.357C6.67139 17.4053 6.56693 17.5178 6.53479 17.6597L4.2205 27.107C4.19639 27.2061 4.20711 27.3106 4.25265 27.399C4.35711 27.6106 4.61425 27.6963 4.82854 27.5919L27.2348 16.4222C27.3178 16.382 27.3848 16.3124 27.4276 16.232C27.5321 16.0178 27.4464 15.7606 27.2348 15.6535ZM6.86157 24.4231L8.20889 18.916L16.116 16.2026C16.1776 16.1811 16.2285 16.1329 16.25 16.0686C16.2875 15.9561 16.2285 15.8356 16.116 15.7954L8.20889 13.0847L6.86693 7.599L23.6884 16.0338L6.86157 24.4231Z'
          fill='white'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_1584_31446'>
        <rect width='24' height='24' fill='white' transform='translate(4 4)' />
      </clipPath>
    </defs>
  </svg>
);

export default SendProjectIcon;

import React from "react";

const InstagramIcon = () => (
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M16.5509 12.8763C14.8306 12.8763 13.4267 14.2802 13.4267 16.0005C13.4267 17.7208 14.8306 19.1247 16.5509 19.1247C18.2712 19.1247 19.6751 17.7208 19.6751 16.0005C19.6751 14.2802 18.2712 12.8763 16.5509 12.8763ZM25.9212 16.0005C25.9212 14.7068 25.9329 13.4247 25.8603 12.1333C25.7876 10.6333 25.4454 9.30206 24.3485 8.20519C23.2493 7.10597 21.9204 6.76613 20.4204 6.69347C19.1267 6.62081 17.8446 6.63253 16.5532 6.63253C15.2595 6.63253 13.9775 6.62081 12.686 6.69347C11.186 6.76613 9.8548 7.10831 8.75792 8.20519C7.6587 9.30441 7.31886 10.6333 7.2462 12.1333C7.17355 13.4271 7.18527 14.7091 7.18527 16.0005C7.18527 17.2919 7.17355 18.5763 7.2462 19.8677C7.31886 21.3677 7.66105 22.6989 8.75792 23.7958C9.85714 24.895 11.186 25.2349 12.686 25.3075C13.9798 25.3802 15.2618 25.3685 16.5532 25.3685C17.847 25.3685 19.129 25.3802 20.4204 25.3075C21.9204 25.2349 23.2517 24.8927 24.3485 23.7958C25.4478 22.6966 25.7876 21.3677 25.8603 19.8677C25.9353 18.5763 25.9212 17.2943 25.9212 16.0005ZM16.5509 20.8075C13.8907 20.8075 11.7439 18.6607 11.7439 16.0005C11.7439 13.3403 13.8907 11.1935 16.5509 11.1935C19.211 11.1935 21.3579 13.3403 21.3579 16.0005C21.3579 18.6607 19.211 20.8075 16.5509 20.8075ZM21.5548 12.1193C20.9337 12.1193 20.4321 11.6177 20.4321 10.9966C20.4321 10.3755 20.9337 9.87394 21.5548 9.87394C22.1759 9.87394 22.6775 10.3755 22.6775 10.9966C22.6776 11.1441 22.6487 11.2901 22.5924 11.4264C22.536 11.5627 22.4533 11.6866 22.349 11.7908C22.2448 11.8951 22.1209 11.9778 21.9846 12.0342C21.8484 12.0905 21.7023 12.1194 21.5548 12.1193Z'
      fill='#6DD8CD'
    />
  </svg>
);

export default InstagramIcon;
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import ApiReducer from "@common/ApiSlice";
import GlobalDataReducer from "@common/GlobalDataSlice";
import ProjectInfoReducer from "@common/ProjectInfoSlice";
import ReportIXDSliceReducer from "@common/ReportIXDSlice";
import ReportReducer from "@common/ReportSlice";
import ReportUFReducer from "@common/ReportUFSlice";
import UserInfoReducer from "@common/UserInfoSlice";
import GUnitConversionReducer from "@common/utils/GlobalUnitConversionSlice";

import { postApi } from "@services/apiConfig";

import activityMonitorSlice from "@features/feedwater/activitymonitor/activityMonitorSlice";
import tabChangeSlice from "@features/feedwater/activitymonitor/tabChangeSlice";
import FeedsetupdetailsdatapanelReducer from "@features/feedwater/feedsetup/FeedsetupSlice";
import IXStoreReducer from "@features/feedwater/ix/IXDSlice";
import OpenProjectStoreReducer from "@features/feedwater/modals/OpenProjectSlice";
import processDiagramSlice from "@features/feedwater/systemdesign/processDiagramSlice";
import UFStoreReducer from "@features/feedwater/uf/UFSlice";
import CardListReducer from "@features/home/CardListSlice";
import FolderviewReducer from "@features/home/ViewAllFolderSlice";
import AuthReducer from "@features/login/AuthSlice";
import LeftPanelReducer from "@features/menu/SideMenuSlice";

export const store = configureStore({
  reducer: {
    globalAppData: GlobalDataReducer,
    cardlist: CardListReducer,
    leftpanel: LeftPanelReducer,
    Folderview: FolderviewReducer,
    Auth: AuthReducer,
    Feedsetupdetailsdatapanel: FeedsetupdetailsdatapanelReducer,
    IXStore: IXStoreReducer,
    OpenProjectStore: OpenProjectStoreReducer,
    GUnitConversion: GUnitConversionReducer,
    UFStore: UFStoreReducer,
    userInfo: UserInfoReducer,
    projectInfo: ProjectInfoReducer,
    ReportIXD: ReportIXDSliceReducer,
    [postApi.reducerPath]: postApi.reducer,
    processDiagramSlice: processDiagramSlice,
    scrollData: activityMonitorSlice,
    tabData: tabChangeSlice,
    ReportUF: ReportUFReducer,
    apiData: ApiReducer,
    report: ReportReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(postApi.middleware),
  devTools: {
    trace: true, // Enable tracing
  },
});
setupListeners(store.dispatch);

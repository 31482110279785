import moment from "moment";

import timezones from "./timezone.json";

/**
 * Parse the offset string and convert it to a comparable value.
 * @param {string} offset - The offset string in the format "UTC±HH:MM".
 * @return {number} - The comparable value of the offset in minutes.
 */
const parseOffset = offset => {
  const match = offset.match(/UTC([+-])(\d{2}):(\d{2})/);
  if (!match) return 0;

  const sign = match[1] === "+" ? 1 : -1;
  const hours = parseInt(match[2], 10);
  const minutes = parseInt(match[3], 10);

  return sign * (hours * 60 + minutes);
};

/**
 * Sort the timezones based on the offset.
 * @param {Array} timezones - The array of timezones to sort.
 * @return {Array} - The sorted array of timezones.
 */
export const sortTimezonesByOffset = timezones =>
  timezones.sort((a, b) => {
    const offsetA = parseOffset(a.offset);
    const offsetB = parseOffset(b.offset);
    return offsetA - offsetB;
  });

export const getCurrentTimezone = () => {
  const timeZoneFromOffset = timezones.filter(timezone => timezone.offset === "UTC" + moment().format("Z"));

  return timeZoneFromOffset.length > 0 ? timeZoneFromOffset[0].timezone : "UTC";
};

import React from "react";

const DeleteProjectEmptyStateIcon = () => (
  <>
    <svg width='148' height='175' viewBox='0 0 148 175' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle opacity='0.3' cx='74' cy='89' r='74' fill='url(#paint0_linear_1428_35392)' />
      <g filter='url(#filter0_ddd_1428_35392)'>
        <circle cx='92' cy='69' r='8' fill='url(#paint1_linear_1428_35392)' />
      </g>
      <g filter='url(#filter1_ddd_1428_35392)'>
        <circle cx='101' cy='39' r='4' fill='url(#paint2_linear_1428_35392)' />
      </g>
      <g filter='url(#filter2_ddd_1428_35392)'>
        <circle cx='87' cy='29' r='2' fill='url(#paint3_linear_1428_35392)' />
      </g>
      <g filter='url(#filter3_ddd_1428_35392)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M100.262 54.946L89.1846 57.2264L87.7594 50.3028C87.1305 47.2478 84.1354 45.2753 81.0803 45.9041L53.386 51.6051C50.331 52.2339 48.3585 55.2291 48.9873 58.2841L50.4126 65.2077L39.3348 67.4881C37.803 67.8034 36.8202 69.2957 37.1355 70.8276L37.7056 73.597C37.784 73.9778 38.1597 74.2252 38.5405 74.1468L43.7678 73.0708L98.3948 61.8257L103.622 60.7497C104.003 60.6713 104.25 60.2956 104.172 59.9148L103.602 57.1454C103.287 55.6135 101.794 54.6307 100.262 54.946ZM56.6443 63.9243L82.9539 58.5084L81.6712 52.2772L55.3616 57.6931L56.6443 63.9243Z'
          fill='#FDFDFD'
        />
      </g>
      <g filter='url(#filter4_ddd_1428_35392)'>
        <path
          d='M53.8286 129.602C50.8156 129.602 48.3239 127.225 48.1825 124.212L46 78H101.772L99.59 124.212C99.4486 127.234 96.9657 129.602 93.9438 129.602H53.8286Z'
          fill='black'
          fillOpacity='0.85'
        />
        <path
          d='M53.8286 129.602C50.8156 129.602 48.3239 127.225 48.1825 124.212L46 78H101.772L99.59 124.212C99.4486 127.234 96.9657 129.602 93.9438 129.602H53.8286Z'
          fill='url(#paint4_linear_1428_35392)'
        />
      </g>
      <path d='M61 89L61 121' stroke='white' strokeWidth='4' strokeLinecap='round' />
      <path d='M74 89L74 121' stroke='white' strokeWidth='4' strokeLinecap='round' />
      <path d='M87 89L87 121' stroke='white' strokeWidth='4' strokeLinecap='round' />
      <defs>
        <filter
          id='filter0_ddd_1428_35392'
          x='48'
          y='34'
          width='88'
          height='88'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology radius='8' operator='dilate' in='SourceAlpha' result='effect1_dropShadow_1428_35392' />
          <feOffset dy='9' />
          <feGaussianBlur stdDeviation='14' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1428_35392' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='6' />
          <feGaussianBlur stdDeviation='8' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0' />
          <feBlend mode='normal' in2='effect1_dropShadow_1428_35392' result='effect2_dropShadow_1428_35392' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology radius='4' operator='erode' in='SourceAlpha' result='effect3_dropShadow_1428_35392' />
          <feOffset dy='3' />
          <feGaussianBlur stdDeviation='3' />
          <feColorMatrix type='matrix' values='0 0 0 0 0.0705882 0 0 0 0 0.513726 0 0 0 0 0.439216 0 0 0 0.12 0' />
          <feBlend mode='normal' in2='effect2_dropShadow_1428_35392' result='effect3_dropShadow_1428_35392' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect3_dropShadow_1428_35392' result='shape' />
        </filter>
        <filter
          id='filter1_ddd_1428_35392'
          x='61'
          y='8'
          width='80'
          height='80'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology radius='8' operator='dilate' in='SourceAlpha' result='effect1_dropShadow_1428_35392' />
          <feOffset dy='9' />
          <feGaussianBlur stdDeviation='14' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1428_35392' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='6' />
          <feGaussianBlur stdDeviation='8' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0' />
          <feBlend mode='normal' in2='effect1_dropShadow_1428_35392' result='effect2_dropShadow_1428_35392' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology radius='4' operator='erode' in='SourceAlpha' result='effect3_dropShadow_1428_35392' />
          <feOffset dy='3' />
          <feGaussianBlur stdDeviation='3' />
          <feColorMatrix type='matrix' values='0 0 0 0 0.0705882 0 0 0 0 0.513726 0 0 0 0 0.439216 0 0 0 0.12 0' />
          <feBlend mode='normal' in2='effect2_dropShadow_1428_35392' result='effect3_dropShadow_1428_35392' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect3_dropShadow_1428_35392' result='shape' />
        </filter>
        <filter
          id='filter2_ddd_1428_35392'
          x='49'
          y='0'
          width='76'
          height='76'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology radius='8' operator='dilate' in='SourceAlpha' result='effect1_dropShadow_1428_35392' />
          <feOffset dy='9' />
          <feGaussianBlur stdDeviation='14' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1428_35392' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='6' />
          <feGaussianBlur stdDeviation='8' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0' />
          <feBlend mode='normal' in2='effect1_dropShadow_1428_35392' result='effect2_dropShadow_1428_35392' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology radius='4' operator='erode' in='SourceAlpha' result='effect3_dropShadow_1428_35392' />
          <feOffset dy='3' />
          <feGaussianBlur stdDeviation='3' />
          <feColorMatrix type='matrix' values='0 0 0 0 0.0705882 0 0 0 0 0.513726 0 0 0 0 0.439216 0 0 0 0.12 0' />
          <feBlend mode='normal' in2='effect2_dropShadow_1428_35392' result='effect3_dropShadow_1428_35392' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect3_dropShadow_1428_35392' result='shape' />
        </filter>
        <filter
          id='filter3_ddd_1428_35392'
          x='1.07617'
          y='18.7871'
          width='139.109'
          height='100.375'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology radius='8' operator='dilate' in='SourceAlpha' result='effect1_dropShadow_1428_35392' />
          <feOffset dy='9' />
          <feGaussianBlur stdDeviation='14' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1428_35392' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='6' />
          <feGaussianBlur stdDeviation='8' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0' />
          <feBlend mode='normal' in2='effect1_dropShadow_1428_35392' result='effect2_dropShadow_1428_35392' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology radius='4' operator='erode' in='SourceAlpha' result='effect3_dropShadow_1428_35392' />
          <feOffset dy='3' />
          <feGaussianBlur stdDeviation='3' />
          <feColorMatrix type='matrix' values='0 0 0 0 0.0705882 0 0 0 0 0.513726 0 0 0 0 0.439216 0 0 0 0.12 0' />
          <feBlend mode='normal' in2='effect2_dropShadow_1428_35392' result='effect3_dropShadow_1428_35392' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect3_dropShadow_1428_35392' result='shape' />
        </filter>
        <filter
          id='filter4_ddd_1428_35392'
          x='10'
          y='51'
          width='127.771'
          height='123.602'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology radius='8' operator='dilate' in='SourceAlpha' result='effect1_dropShadow_1428_35392' />
          <feOffset dy='9' />
          <feGaussianBlur stdDeviation='14' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1428_35392' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='6' />
          <feGaussianBlur stdDeviation='8' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0' />
          <feBlend mode='normal' in2='effect1_dropShadow_1428_35392' result='effect2_dropShadow_1428_35392' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology radius='4' operator='erode' in='SourceAlpha' result='effect3_dropShadow_1428_35392' />
          <feOffset dy='3' />
          <feGaussianBlur stdDeviation='3' />
          <feColorMatrix type='matrix' values='0 0 0 0 0.0705882 0 0 0 0 0.513726 0 0 0 0 0.439216 0 0 0 0.12 0' />
          <feBlend mode='normal' in2='effect2_dropShadow_1428_35392' result='effect3_dropShadow_1428_35392' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect3_dropShadow_1428_35392' result='shape' />
        </filter>
        <linearGradient id='paint0_linear_1428_35392' x1='74' y1='15' x2='74' y2='163' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#6DD8CD' />
          <stop offset='1' stopColor='#E2F7F5' />
        </linearGradient>
        <linearGradient id='paint1_linear_1428_35392' x1='92' y1='61' x2='92' y2='77' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#128370' />
          <stop offset='1' stopColor='#E2F7F5' />
        </linearGradient>
        <linearGradient id='paint2_linear_1428_35392' x1='101' y1='35' x2='101' y2='43' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#128370' />
          <stop offset='1' stopColor='#E2F7F5' />
        </linearGradient>
        <linearGradient id='paint3_linear_1428_35392' x1='87' y1='27' x2='87' y2='31' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#128370' />
          <stop offset='1' stopColor='#E2F7F5' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_1428_35392'
          x1='73.8862'
          y1='78'
          x2='73.8862'
          y2='129.602'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F8F8F8' />
          <stop offset='1' stopColor='#E1E1E1' />
        </linearGradient>
      </defs>
    </svg>
  </>
);
export default DeleteProjectEmptyStateIcon;

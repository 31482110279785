import React from "react";

const FavEmptyStateIcon = () => (
  <>
    <svg width='159' height='166' viewBox='0 0 159 166' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle opacity='0.3' cx='85' cy='74' r='74' fill='url(#paint0_linear_1416_37265)' />
      <g filter='url(#filter0_ddd_1416_37265)'>
        <path
          d='M49.2436 37.1864C50.068 32.9435 54.1759 30.1722 58.4189 30.9966L95.2309 38.149C97.6491 38.6189 99.7049 40.1998 100.78 42.4162L104.764 50.6314L109.974 58.9812C111.021 60.6602 111.394 62.6738 111.016 64.6164L101.466 113.771C100.641 118.014 96.5336 120.785 92.2906 119.961L42.9945 110.383C38.7515 109.558 35.9802 105.45 36.8046 101.207L49.2436 37.1864Z'
          fill='url(#paint1_linear_1416_37265)'
        />
        <path d='M59.2266 53.9648L98.4923 61.594' stroke='white' strokeWidth='6' strokeLinecap='round' />
        <path d='M56.9395 65.7441L77.5539 69.7495' stroke='white' strokeWidth='6' strokeLinecap='round' />
        <path
          d='M92.5659 53.5763L95.1942 40.0492C95.5198 38.373 97.6861 37.8891 98.6941 39.2674L105.615 48.7309C106.408 49.8157 107.065 50.9945 107.569 52.2404L111.292 61.4436C111.875 62.8826 110.629 64.3941 109.106 64.098L98.2399 61.9869C94.3506 61.2312 91.8102 57.4656 92.5659 53.5763Z'
          fill='#E1E1E1'
        />
      </g>
      <g clipPath='url(#clip0_1416_37265)'>
        <g filter='url(#filter1_d_1416_37265)'>
          <path
            d='M102.186 117.885C100.072 118.529 97.8374 117.338 97.1935 115.224L89.0326 88.4401L115.817 80.2792C117.93 79.6354 120.165 80.8265 120.809 82.9397L128.97 109.724L102.186 117.885Z'
            fill='#007672'
          />
        </g>
        <g clipPath='url(#clip1_1416_37265)'>
          <path
            d='M114.199 94.9033L110.236 95.508L107.492 92.5857C107.417 92.5057 107.317 92.4527 107.209 92.435C106.938 92.3904 106.68 92.575 106.634 92.8471L105.985 96.8032L102.359 98.511C102.258 98.5578 102.178 98.6363 102.127 98.7334C102.067 98.8513 102.055 98.9886 102.095 99.1151C102.136 99.2415 102.225 99.3468 102.343 99.4077L105.904 101.248L106.407 105.226C106.419 105.318 106.456 105.406 106.515 105.478C106.573 105.551 106.651 105.605 106.739 105.636C106.828 105.666 106.923 105.671 107.014 105.65C107.104 105.63 107.188 105.584 107.254 105.518L110.105 102.699L114.043 103.45C114.15 103.471 114.263 103.454 114.361 103.405C114.607 103.281 114.707 102.981 114.583 102.735L112.782 99.1523L114.712 95.6388C114.765 95.5428 114.785 95.4321 114.768 95.3229C114.729 95.049 114.473 94.8624 114.199 94.9033Z'
            fill='#E2F7F5'
          />
        </g>
      </g>
      <path
        d='M128.547 46.4638L124.584 47.0686L121.84 44.1463C121.765 44.0662 121.665 44.0132 121.557 43.9956C121.285 43.9509 121.028 44.1355 120.982 44.4077L120.333 48.3638L116.706 50.0716C116.606 50.1184 116.526 50.1969 116.475 50.2939C116.414 50.4119 116.403 50.5492 116.443 50.6756C116.483 50.8021 116.572 50.9073 116.69 50.9683L120.252 52.8086L120.755 56.7863C120.766 56.8788 120.804 56.9663 120.862 57.0387C120.921 57.1112 120.999 57.1657 121.087 57.1962C121.175 57.2266 121.27 57.2317 121.361 57.211C121.452 57.1902 121.535 57.1444 121.602 57.0786L124.452 54.2598L128.39 55.0102C128.498 55.0313 128.611 55.0149 128.708 54.9656C128.955 54.8415 129.055 54.5416 128.93 54.2952L127.13 50.7128L129.06 47.1993C129.113 47.1033 129.133 46.9926 129.116 46.8834C129.076 46.6096 128.82 46.423 128.547 46.4638Z'
        fill='#128370'
      />
      <path
        d='M113.966 37.9305L112.625 36.7039L112.876 34.9038C112.883 34.8545 112.873 34.8043 112.849 34.7611C112.787 34.6525 112.649 34.6142 112.54 34.6753L110.959 35.5721L109.324 34.778C109.279 34.756 109.228 34.7498 109.18 34.7596C109.121 34.7717 109.069 34.8068 109.036 34.857C109.003 34.9072 108.991 34.9685 109.003 35.0275L109.367 36.8081L108.106 38.1175C108.077 38.1479 108.056 38.1859 108.047 38.2273C108.039 38.2686 108.042 38.3116 108.056 38.3514C108.07 38.3912 108.095 38.4262 108.129 38.4524C108.162 38.4786 108.202 38.4949 108.244 38.4996L110.05 38.7036L110.906 40.3071C110.929 40.3511 110.967 40.3862 111.012 40.4066C111.126 40.4583 111.26 40.4082 111.312 40.2942L112.064 38.6395L113.853 38.3209C113.902 38.3121 113.947 38.2877 113.98 38.2507C114.066 38.1589 114.059 38.0154 113.966 37.9305Z'
        fill='#128370'
      />
      <defs>
        <filter
          id='filter0_ddd_1416_37265'
          x='0.660156'
          y='3.85156'
          width='146.779'
          height='161.254'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology radius='8' operator='dilate' in='SourceAlpha' result='effect1_dropShadow_1416_37265' />
          <feOffset dy='9' />
          <feGaussianBlur stdDeviation='14' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1416_37265' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='6' />
          <feGaussianBlur stdDeviation='8' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0' />
          <feBlend mode='normal' in2='effect1_dropShadow_1416_37265' result='effect2_dropShadow_1416_37265' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology radius='4' operator='erode' in='SourceAlpha' result='effect3_dropShadow_1416_37265' />
          <feOffset dy='3' />
          <feGaussianBlur stdDeviation='3' />
          <feColorMatrix type='matrix' values='0 0 0 0 0.0705882 0 0 0 0 0.513726 0 0 0 0 0.439216 0 0 0 0.12 0' />
          <feBlend mode='normal' in2='effect2_dropShadow_1416_37265' result='effect3_dropShadow_1416_37265' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect3_dropShadow_1416_37265' result='shape' />
        </filter>
        <filter
          id='filter1_d_1416_37265'
          x='89.0332'
          y='80.1055'
          width='41.9375'
          height='40.9551'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='1' dy='2' />
          <feGaussianBlur stdDeviation='0.5' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1416_37265' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_1416_37265' result='shape' />
        </filter>
        <linearGradient id='paint0_linear_1416_37265' x1='85' y1='0' x2='85' y2='148' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#6DD8CD' />
          <stop offset='1' stopColor='#E2F7F5' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_1416_37265'
          x1='83.0669'
          y1='35.7856'
          x2='67.6426'
          y2='115.172'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F8F8F8' />
          <stop offset='1' stopColor='#E1E1E1' />
        </linearGradient>
        <clipPath id='clip0_1416_37265'>
          <rect width='32' height='32' fill='white' transform='translate(98.3594 119.051) rotate(-106.945)' />
        </clipPath>
        <clipPath id='clip1_1416_37265'>
          <rect width='16' height='16' fill='white' transform='translate(99.0176 93.7617) rotate(-16.9454)' />
        </clipPath>
      </defs>
    </svg>
  </>
);
export default FavEmptyStateIcon;

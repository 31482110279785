import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeFolder: { id: 0, name: "" },
  foldersList: [],
  loader: false,
};

export const ViewAllFolderSlice = createSlice({
  name: "Folderview",
  initialState,
  reducers: {
    updateFolderList: (state, action) => {
      state.foldersList = action.payload;
    },
    updateFolderLoader: (state, action) => {
      state.loader = action.payload;
    },
    updateActiveFolder: (state, action) => {
      state.activeFolder = action.payload;
    },
    resetActiveFolder: state => {
      state.activeFolder = { id: 0, name: "" };
    },
  },
});

export const { updateFolderList, updateFolderLoader, updateActiveFolder, resetActiveFolder } =
  ViewAllFolderSlice.actions;

export default ViewAllFolderSlice.reducer;

import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import useUnitConversion from "@hooks/useUnitConversion";

import BoldTextElement from "../UFDiagramsUtils/BoldTextElement";
import TextElement from "../UFDiagramsUtils/TextElement";
import { formatFlowValue } from "../UFDiagramsUtils/UFDiagramHelper";

const FLOW_METRIC_UNIT = "m³/h";

const UFDiagramWasteWaterDetails = ({ isInge, isCIP }) => {
  const { unitConversionByName } = useUnitConversion();
  const { calcEngineData } = useSelector(state => state.UFStore);
  const { unitConfig } = useSelector(state => state.projectInfo?.projectConfig);

  const [wasteWaterVolumn, setWasteWaterVolumn] = useState(0);

  useEffect(() => {
    const selectedUnit = unitConfig.selectedUnits[1];
    let value = calcEngineData.waste_water_disposal || 0;

    if (value && selectedUnit !== FLOW_METRIC_UNIT) {
      value = unitConversionByName(value, selectedUnit, FLOW_METRIC_UNIT);
    }
    value = formatFlowValue(value, selectedUnit);
    setWasteWaterVolumn(value);
  }, [unitConfig.selectedUnits, calcEngineData]);

  const xyPosition = useMemo(
    () => (isInge ? (isCIP ? { x: 9700, y: 4250 } : { x: 9700, y: 3850 }) : { x: 9700, y: 4250 }),
    [isInge, isCIP],
  );

  return (
    <>
      <BoldTextElement>
        <tspan {...xyPosition}>Waste</tspan>
      </BoldTextElement>
      <TextElement fill='#454545'>
        <tspan x={xyPosition.x} y={xyPosition.y + 140}>
          {wasteWaterVolumn}
        </tspan>
      </TextElement>
    </>
  );
};

export default UFDiagramWasteWaterDetails;

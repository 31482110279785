export const DesignStrings = {
  ufFeedFlowRate: "UF Feed Flow Rate",
  automatic: "Automatic",
  ufTechnologySelection: "UF Technology Selection",
  ufTechnology: "UF Technology",
  designFlowFluxRates: "Design Instantaneous (Gross) Flux and Flow Rates",
  backwashFlush: "Backwash Flux",
  cebFlux: "CEB Flux",
  forwardFlushFlow: "Forward Flush Flow",
  airFlow: "Air Flow",
  aerationAirFlow: "Aeration Air Flow",
  cIPRecycleFlowRate: "CIP Recycle Flow Rate",
  miniCIPRecycleFlowRate: "mini-CIP Recycle Flow Rate",
  forwardFlush: "Forward Flush",
  uFSpecialFeatures: "UF Special Features",
  adjustpHAddOxidantCoagulant: "Adjust pH, Add Oxidant/Coagulant",
  cEBOnly: "CEB only",
  miniCIPOnly: "mini-CIP only",
  CEBAndMiniCIP: "CEB and mini-CIP",
  designCycleInterval: "Design Cycle Intervals",
  filtrationDuration: "Filtration Duration",
  miniCip: "mini-CIP",
  acidCEB: "Acid CEB",
  disinfectionCEB: "Disinfection CEB",
  membraneIntegrityTesting: "Membrane Integrity Testing",
  offlineTimeperUnit: "Offline Time per Unit",
  airScour: "Air Scour",
  drain: "Drain",
  bWRinseDrainBottom: "BW Rinse Drain Bottom",
  bWRinseDrainTop: "BW Rinse Drain Top",
  cebTemprature: "CEB Temperature",
  digramForFeedWater: "Use design temperature from feed water",
  waterSource: "Water Source",
  cebWaterSource: "CEB Water Source",
  mineralAcid: "Mineral Acid",
  organicAcid: "Organic Acid",
  alkaliOxidantCEB: "Alkali/Oxidant CEB",
  alkali: "Alkali",
  oxidant: "Oxidant",
  duration: "Duration",
  durations: "Durations",
  chemicalSoakingDuration: "Chemical Soaking Duration",
  filtratetoLevel: "Filtrate to Level",
  lumenFill: "Lumen Fill",
  backwashesbetweenAirScour: "Backwashes between Air Scour",
  backwashTemprature: "Backwash Temperature",
  backwashWaterSource: "Backwash Water Source",
  forwardFlushWaterSource: "Forward Flush Water Source",
  backwashProtocol: "Backwash Protocol",
  cIP: "CIP",
  filtrateFlux: "Filtrate Flux",
  cipRecycleFlowRateInfo:
    "The CIP Recycle Flow Rate refers to the amount of fluid entering the system through the CIP Inlet Pipe during the cleaning process. During this step, the flow divides into two parts: one part is  the filtrate and the other part is the concentrate. Both parts are then circulated back to the modules.",
  tocRejection: "TOC Rejection",
  tocRejectionCOD: "COD (as KMnO4) Rejection",
  tocRejectionTitle: "UF Organics Rejection",
  tocRejectionNote:
    "Note: This feature is used to account for organics rejection by UF membranes. A default value is given but users are encouraged to use a relevant figure to their system.",
  designModuleWarning: "Technology Change Warning",
  designModuleWarningMessage: valueChange =>
    `This ${valueChange} change will update your parameters with default values. Please review before generating report.`,
};

export const IngeString = {
  topbackwash: "BW Drain Bottom",
  bottomBackwash: "BW Drain Top",
  cebTopBackwash: "CEB Injection Drain Bottom",
  cebBottomBackwash: "CEB Injection Drain Top",
  selectedConfRackLabel: "T-Rack™ Design",
  recommConfigSliderMinRange: "Min T-Rack™ Size Limit: ",
  recommConfigSliderMaxRange: " :Max T-Rack™ Size Limit",
  backwashProtocolVal: "Normal protocol (RCBW)",
  notEvenMultiple: multiplier =>
    `That’s not a standard configuration. We recommend modules/units to be a multiple of ${multiplier}, but we will accept it.`,
  moduleUnitWarning: "Module/Unit",
};

export const DupontString = {
  topbackwash: "Top Backwash",
  bottomBackwash: "Bottom Backwash",
  cebTopBackwash: "Top Backwash",
  cebBottomBackwash: "Bottom Backwash",
  selectedConfRackLabel: "IP Rack Design",
  recommConfigSliderMinRange: "Min IP Rack Size Limit: ",
  recommConfigSliderMaxRange: " :Max IP Rack Size Limit",
  backwashProtocolVal: "Normal protocol",
};

export const ValidationStrings = {
  slsRecommendedRange: "SLS Recommended Range",
  cipMineralAcidConectration: "CIP Mineral Acid Concentration",
  cipOrganicAcidConcentration: "CIP Organic Acid Concentration",
  cipAlkaliConcentration: "CIP Alkali Concentration",
  cipOxidantConcentration: "CIP Oxidant Concentration",
  cipRecycle: "CIP Recycle",
  cipHeatingStep: "CIP Heating Step",
  cipSoak: "CIP Soak",
};

export const FolderStrings = {
  restoreFolder: "Restore Folder",
  folderCreateSuccess: "The folder has been successfully created.",
  folderExists: "A folder with this name already exists. Specify a different name.",
  renameFolder: "Rename Folder",
  folderName: "Folder Name",
  createNewFolder: "Create New Folder",
  folderNameLength: "Folder name must contain 3 to 200 characters",
  folderNameCannotBeBlank: "Folder name cannot be blank.",
  createFolder: "Create Folder",
  folderNameAlreadyExist: "Folder name already exist",
  specialCharactersNotAllowed: "Folder name can't contain special characters: \\ / : * ? \" < > | ",
  openFolder: "Open Folder",
  deleteFolder: "Delete Folder",
  deleteFolderTilte: "Delete folder?",
  folderDeleteConfirmation: "For 30 days you can find this in the Deleted directory and restore",
  permanentDeleteFolderConfirmation: "This can't be undone, Do you want to permanently delete this folder?",
  addFolderHelperText:
    "Add Project to any folder listed below or you can create a new folder also based on your prefrences.",
};

export const IXStrings = {};

export const IXDStrings = {
  demineralization: "IX Demineralization",
  backwashFrequency: "Backwash Frequency (Cycles Between BW)",
  backwashExpansion: "Backwash Expansion (%)",
  backwashDuration: "Backwash Duration (min)",
};

export const IXMBStrings = {
  demineralization: "Mixed Bed",
  backwashFrequency: "MB Separation Backwash Frequency(Cycles)",
  backwashExpansion: "MB Separation Backwash Expansion",
  backwashDuration: "MB Separation Backwash Duration (min)",
  regenrationHoldDownFaction: "SBA/SAC Regeneration Flow Ration",
  WaterChemistryAdjustments: "Water Chemistry Adjustments",
};

export const ImportProjectStrings = {
  projectAlreadyExists: "Project already exists",
  projectNameKey: "diffgr:diffgram/NewDataSet/Project/ProjectName",
  errorParsingXML: "Error parsing XML",
  checkProjectDetails: "Check Project Details...",
  import: "Import",
  importing: "importing",
  renaming: "renaming",
  renameYourProject: "Rename your project",
  errorImportingFile: "Error importing your project",
  invalidFileExtension: "Invalid file extension",
  pleaseCheckTheFileName: "Please check the project name before {type}.",
  editFileName: "Project Name",
  projectNameExist: "Project name already exist",
  projectNameExistDoYouWantToReplace: "Project name already exist, Do you want to replace?",
  projectReplaceConfirmation: "Are you sure you want to replace the existing project? This action cannot be undone.",
  cancel: "Cancel",
  replace: "Replace",
  rename: "Rename",
  replaceProject: "Replace Project",
  errorExportingFile: "Error exporting your project",
  exporingProject: "Exporting Project",
  duplicatingProject: "Duplicating Project",
  successfulExport: "Project has been exported successfully!",
  inputFieldCannotBeEmpty: "Input field cannot be empty.",
  minLengthError: minLength => `Name must be ${minLength} characters long.`,
  projectWithNameAlreadyExists: truncatedProjectName => `Project with name ${truncatedProjectName} already exists`,
  saveAsDwpro: "Save as .dwpro",
  acceptedExtensions: "File format not supported. Please upload a .dwpx or .dwpro file",
  replaceProjectQuestionMark: "Replace existing project?",
  importProject: "Import Project",
  importProjectHelperText: "This might take time depending on your file size",
  whatToDoNext: "What do you want to do next?",
  uploadingProject: "Uploading project",
  successfullyImported: "Successfully imported project",
  invalidWaterSubTypeId: "Mismatch detected in water subtype",
  invalidWaterSubtypeDesc:
    "The imported file includes a custom pairing of water subtype with NTU, TOC, and TSS values, which isn’t supported in WAVE PRO. Would you like to proceed and reset to the default configuration?",
  resetAndProcceed: "Reset & Proceed",
  cancelImport: "Cancel Import",
};
export const CaseManagementStrings = {
  cases: "Cases",
  manageCase: "Manage Case",
  addCase: "Add Case",
  addNewCase: "Add New Case",
  editCase: "Edit Case",
  deleteCase: "Delete Case",
  openCase: "Open Case",
  caseNo: "Case No.",
  more: "More",
  switchToCase: "Switch to Case",
  add: "Add",
  save: "Save",
  update: "Update",
  cancel: "Cancel",
  Okay: "Okay",
  caseName: "Case Name",
  caseNotes: "Case Notes",
  close: "Close",
  actions: "Actions",
  caseManagement: "Case Management",
  caseManagementEditDelete:
    "You can add, edit, delete and reorder cases here based on your preference. To reorder hold the row and drag it up and down.",
  moveUp: "Move Up",
  moveDown: "Move Down",
  deleteCaseWarning: "This step is irreversible and all your data within that case will be lost.",
  caseAdded: "Case added",
  caseNameExists: "Case name already exists",
  deleteCaseTitle: "Delete case?",
  addCaseLoader: "Adding case",
  updateCaseLoader: "Updating case",
  dragToReorder: "Reorder",
};

export const ToolTips = {
  ingeBackwash:
    "The normal protocol is the Reverse Combined Backwash Protocol (RCBW), which means that during the change from Backwash Drain Bottom to Backwash Drain Top both valves are open at the same time during 5 sec.",
  dupontBackwash: "Select BW protocol if specified module is suitable for more than one.",
  sendProject: "Send Project",
};

export const ModulTypeStrings = {
  rack: "Rack",
  openPlatform: "Open Platform",
};

export const CommonButtonStrings = {
  cancel: "Cancel",
  okay: "Okay",
  delete: "Delete",
  createNewProject: "Create New Project",
  createNewProjectDescription: "Start your .dwpro project now",
  importProject: "Import Project",
  addToFolder: "Add to Folder",
  openProject: "Open Project",
  createNewFolder: "Create Folder",
  organiseYourProjects: "Organize your projects",
  supportedFileTypes: "Only .dwpx or .dwpro allowed",
  supportedTechnologies: "UF, IXMB",
  supportedTechnologiesDev: "UF, IXMB, IXD",
  dismiss: "Dismiss",
  yes: "Yes",
  no: "No",
  edit: "Edit",
  submit: "Submit",
  renameFolder: "Rename Folder",
  save: "Save",
  skip: "Skip",
  close: "Close",
};

export const ProjectStrings = {
  deleteProjectTitle: "Delete project?",
  deleteNote: "For 30 days you can find this in the Deleted directory and restore",
  deleteNotePermanent: "This can't be undone, Do you want to permanently delete this project",
  renameThisProject: "Rename this project",
  projectNameAlreadyExist: "Project name already exist",
  projectLengthValidation: "Project name must contain 3 to 200 characters",
  projectInputFieldValidation: "Project name cannot be blank.",
  editedJustNow: "Edited just now",
  createJustNow: "Created just now",
  projectName: "Project Name",
  addToFavorite: "Add to Favorite",
  removeFromFavorite: "Remove from Favorite",
  duplicateProject: "Duplicate Project",
  renameProject: "Rename Project",
  exportProject: "Export Project",
  sendProject: "Send Project",
  deleteProject: "Delete Project",
  restoreProject: "Restore Project",
  permanentlyDelete: "Permanently Delete",
  removeFromFolder: "Remove from Folder",
};

export const PumpStrings = {
  totalEfficencyTooltip:
    "Total Efficiency takes into account pump and motor efficiency: Total Efficiency = Pump Efficiency * Motor Efficiency",
};

export const userProfileMenuStrings = {
  contactDupont: "Contact (dupont.com)",
};

export const projectInfoModalStrings = {
  defaultState: "Select State",
  defaultCountry: "Select Country",
  defaultMarketSegment: "Select Market Segment",
  defaultMarketSegmentFilter: "All",
};

export const CarouselStrings = {
  welcometoWavePro: "Welcome to WavePro",
  workingTogetherForFuture: "Working together for a clean-water future",
};

export const OpenProjectFilterLabels = {
  equal: "Equal",
  doesnotequal: "Does not equal",
  beginswith: "Begins with",
  endswith: "Ends with",
  contains: "Contains",
  doesnotcontains: "Does not contain",
};

export const WaterLibraryStrings = {
  preDefinedLibraryLabel: "Default dropdown list start from here",
};

export const SaveProjectValidationMessages = {
  saveValidationMessage: "You have unsaved data, do you want to save the project?",
};

export const FileAcessStrings = {
  fileAccessLabel: "File Access",
  openNewProject: "New Project",
  showOpenProject: "Open Project",
  recentProject: "Recent Projects",
  exportProject: "Save as .dwpro",
  navigateHome: "Back to Home",
};

export const UserSettingsStrings = {
  welcometoWavePro: "Welcome to WAVE PRO",
  selectTimezone: "Default Time Zone",
  selectLanguage: "Select Language",
  helperNote:
    "Enter below details for account Prefrences. They can be changed later. Note the profile picture can't be more than 100KB",
  technologyGroup: "Which of the technology group do you prefer to use in WAVE PRO?",
  technologyGroupNote:
    "It can be changed from inside the project. Only the selected technology will appear in the System Design page.",
  mobileNumber: "Mobile Number",
  preTreatment: "Pre-treatment",
  bulkDemineralization: "Bulk Demineralization",
  traceRemoval: "Trace Contaminants Removal",
  polishing: "Polishing",
  selectedTechnologies: "Selected Technologies",
};

export const SystemDesignStrings = {
  VIEWING_TECHNOLOGIES: "You are viewing technologies as per your preference and can edit them from Project Settings.",
  SPECIFY_FLOW_RATE: "Specify either Feed flow rate OR Product flow rate.",
  SELECT_TECHNOLOGIES:
    "Select technologies by dragging and dropping one or more icons from the top panel to the center of the screen, between Feed Water and Product Water box.",
};

export const SendProjectStrings = {
  headingLabel: "You may add multiple email IDs at once and send a copy of this project.",
  emailInputBoxLabel: "Email Address",
  previouslySentUserLabel: "Project previously sent to",
  sendAgainButton: "Send Again",
  sendProjectButton: "Send Project",
};

export const EMPTY_STATE_STRINGS = {
  all_projects: {
    title: "No projects created yet",
    description: "Tap the button below to start creating a Wave project",
    buttonText: "Create Project",
  },
  favourite_projects: {
    title: "No favourite projects",
    description: "Add a project to favourites by using the more menu or dragging it to favourites",
  },
  imported_projects: {
    title: "No projects imported",
    description: "Tap the button below to import .dwpx files into WavePro",
    buttonText: "Import Project",
  },
  deleted_items: {
    title: "No deleted projects",
    description: "Projects deleted by you will appear here and stay for 30 days before being permanently deleted",
  },
  folders: {
    title: "No folders created yet",
    description: "Tap the button below to create folders and start organizing your projects",
    buttonText: "New Folder",
  },
  search_items: {
    title: "No projects found",
    description: "Please change your query or try resetting your filters",
  },
  search_items_folder: {
    title: "No folders found",
    description: "Please change your query or try resetting your filters",
  },
  folderProjectNotFound: {
    title: "No projects added to folder",
    description: "Add by dragging project tile to folder or from more menu",
  },
};

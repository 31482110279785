import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { UNITS } from "@constants/units.constant";

import useUnitConversion from "@hooks/useUnitConversion";

import { convertUptoDigits } from "@utils/appUtils";

import TextElement from "../UFDiagramsUtils/TextElement";

const VOLUMN_METRIC_UNIT = "m³";

const UFDiagramTankBackWashDetails = ({ isInge, isCIP }) => {
  const { unitConversionByName } = useUnitConversion();
  const { calcEngineData } = useSelector(state => state.UFStore);
  const { unitConfig } = useSelector(state => state.projectInfo?.projectConfig);
  const [tankVolumn, setTankVolumn] = useState("");

  useEffect(() => {
    const selectedUnit = unitConfig.selectedUnits[11];
    let value = calcEngineData.vol_BW_Tank || 0;

    if (value && selectedUnit !== VOLUMN_METRIC_UNIT) {
      value = unitConversionByName(value, selectedUnit, VOLUMN_METRIC_UNIT);
    }
    const fractionDigits = selectedUnit === UNITS.CUBIC_METER ? 3 : selectedUnit === UNITS.gallon ? 1 : 0;
    value = convertUptoDigits(value, fractionDigits);

    setTankVolumn(`${value} ${selectedUnit}`);
  }, [unitConfig.selectedUnits, calcEngineData]);

  const xyPosition = useMemo(
    () => ({ x: isInge ? (isCIP ? 8775 : 8650) : 8700, y: isInge ? (isCIP ? 2950 : 2550) : 2850 }),
    [isInge, isCIP],
  );

  return (
    <>
      <TextElement fill='white' fontSize='160'>
        <tspan {...xyPosition}>BW</tspan>
        <tspan x={xyPosition.x - 25} y={xyPosition.y + 275}>
          Tank
        </tspan>
      </TextElement>
      <TextElement fill='white' fontSize='120'>
        <tspan x={xyPosition.x - 25} y={xyPosition.y + 500}>
          {tankVolumn}
        </tspan>
      </TextElement>
    </>
  );
};

export default UFDiagramTankBackWashDetails;

import { Fragment, lazy, Suspense, useMemo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { FullScreenLoader } from "@components/FullScreenLoader";

import PrivateRoute from "./PrivateRoute";
import WP_App_Routes from "./Routes";

const FeedWaterHome = lazy(() => import("./features/feedwater/FeedWaterHome"));
const Home = lazy(() => import("./features/home/Home"));
const AboutWavePro = lazy(() => import("./features/aboutWavePro"));
const HelpFAQWavePro = lazy(() => import("./features/helpFAQWavePro"));
const LogoutWavePro = lazy(() => import("./features/sso/SSOLogout"));
const LoginWavePro = lazy(() => import("./features/sso/SSOLogin"));
const LoginWaveProSSOCallback = lazy(() => import("./features/sso/SSOCallback"));

function Routing() {
  const routes = useMemo(
    () => (
      <Routes>
        <Route path={WP_App_Routes.about} element={<AboutWavePro />} />
        <Route path={WP_App_Routes.help} element={<HelpFAQWavePro />} />
        <Route path={WP_App_Routes.logout} element={<LogoutWavePro />} />
        <Route path={WP_App_Routes.base} element={<LoginWavePro />} />
        <Route path={WP_App_Routes.ssoCallback} element={<LoginWaveProSSOCallback />} />
        <Route element={<PrivateRoute />}>
          <Route path={WP_App_Routes.home} element={<Home />} />
          <Route path={WP_App_Routes.project} element={<FeedWaterHome />} />
        </Route>
        <Route path={WP_App_Routes.wildcard} element={<Navigate to={WP_App_Routes.base} />} />
      </Routes>
    ),
    [],
  );

  return (
    <>
      <div style={{ minHeight: "100vh" }}>
        <Suspense fallback={<FullScreenLoader />}>{routes}</Suspense>
      </div>
    </>
  );
}

export default Routing;

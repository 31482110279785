export const MAX_TEMP_CELCIUS = 100;
// #141828 IF TDS is less than this, then it will be consider, there is no water chemistry
export const NO_WATER_CHEMISTRY_TDS_DELTA = 0.1;
export const MAX_TEMP_CELCIUS_WARNING = 40;
export const VALIDATION_DELAY_MS = 3000;
export const SYSTEM_DIAGRAM_HIDE_SHOW_MSG_TIMER = 3000;
export const DEFAULT_DUPONT_TEMP = 35; // Default temperature in Celsius
export const COMPANY_CODE = { 1: "DUPONT", 2: "INGE" };
export const UF_MODULE_PER_RACK_RANGE = { min: 6, max: 22 };
export const UF_CHEM_ADJUSTMENT_SCREEN = "CHEMICAL_ADJUSTMENT_DIALOG_SCREEN";
export const UF_CHEMICAL_SCREENS = { CEB: "CEB", MINI_CIP: "MiniCIP", CIP: "CIP", BW: "BW" };
export const WATER_TYPES_OFFLINE_TRAINS = { DRINKNG: 12, NON_DRINKING: 0 };
export const DEFAULT_WATER_TYPE = { waterTypeId: 5, waterSource: "Surface Water" };
export const DISABLED_INPUTS = ["Automatic", "Aeration Air Flow", "Disinfection CEB"];
export const UF_STANDBY_OPTION = { FILTRATE_TANK: 1, BACKWASH_TANK: 2 };
export const INGE_DUPONT_BW_ID = 2;
export const DEFAULT_WATER_TYPE_ID = 5;
export const DEFAULT_WATER_SUB_TYPE_ID = 51;

export const UF_FEED_PUMP_MAX = {
  DUPONT: 2.1, // #143980
  INGE: 1.5,
};

export const UF_CHEMICAL_SCREENS_LABELS = {
  [UF_CHEMICAL_SCREENS.CEB]: "CEB",
  [UF_CHEMICAL_SCREENS.MINI_CIP]: "mini-CIP",
  [UF_CHEMICAL_SCREENS.CIP]: "CIP",
  [UF_CHEMICAL_SCREENS.BW]: "BW",
};

export const UF_SPECIAL_FEATURE = {
  isCebOnly: "1",
  isMiniCipOnly: "2",
  isCebAndMiniCip: "3",
};

export const WATER_TYPE_IDS = {
  SOFTENED_WATER: 2,
  MUNICIPAL_WATER: 3,
  WELL_WATER: 4,
  SURFACE_WATER: 5,
  SEA_WATER: 6,
  WASTE_WATER: 7,
  BACKWASH_WATER: 8,
};

export const FIELD_OFFLINE_TIME_PER_TRAIN = {
  defaultValue: 0,
  minValue: 0,
  maxValue: 60,
  softLowerLimit: 2,
  softUpperLimit: 30,
  uom: "min/d",
};

export const MODULE_COMPANY_NAMES = {
  DUPONT: "DuPont",
  INGE_MB_07: "Inge MB 0.7",
  INGE_MB_09: "Inge MB 0.9",
  INGE_MB_15: "Inge MB 1.5",
};

export const UFTechnologies = [
  {
    pUFTechnologyID: 1,
    companyName: "DuPont",
    pUFTechName: "PVDF Out-In",
  },
  {
    pUFTechnologyID: 2,
    companyName: "Inge",
    pUFTechName: "PES In-Out",
  },
];

export const UF_MODULE_TABLE_CONFIG = [
  { id: 1, title: "Module Name", field: "moduleName", filter: true }, //filter needs to be set true to use filter icon
  { id: 2, title: "Fiber Material", field: "fberMat" },
  { id: 3, title: "Operation Mode", field: "flowPattern" },
  { id: 4, title: "Module Area(m²)", field: "moduleArea" },
  { id: 5, title: "Length(mm)", field: "moduleLength" },
  { id: 6, title: "Rack/Open Platform", field: "integrated" },
  { id: 7, title: "Orientation", field: "orientation" },
  { id: 8, title: "Inner Diameter(mm)", field: "bores" },
];

export const MODULE_TYPES = {
  RACK: "rack",
  OPEN_PLATFORM: "open_platform",
  DRINKING_WATER: "drinking_water",
  NON_DRINKING_WATER: "non_drinking_water",
  OBSOLETE: "obsolete",
  CHINESE: "chinese",
};

//string constants

export const ERR_MSGS = {
  ACID_CEB_DOSE_ERROR:
    "The values for both Mineral and Organic Acid Dose are 0. To continue, please adjust at least one of the doses amounts to a value greater than 0 in the CEB display.",
  ALKALI_CEB__DOSE_ERROR:
    "The values for both CEB Alkali and CEB Oxidant Dose are 0. To continue, please adjust at least one of the doses amounts to a value greater than 0 in the CEB display.",
  CIP_DOSE_ERROR:
    "The value for CIP chemical doses are currently 0. To continue, please adjust the at least one of the CIP chemical doses amounts to a value greater than 0 in the CIP display.",
  CIP_DESIGN_VALUE_ERROR:
    "The CIP design cycle interval is 0 , please update the value to a value greater than 0 in the design display",
  ALKALI_CEB_DESIGN_VALUE_ERROR:
    "The alkali/oxidant CEB design cycle interval is 0 , please update the value to a value greater than 0 in the design display",
  ACID_CEB_DESIGN_VALUE_ERROR:
    "The Acid CEB design cycle interval is 0 , please update the value to a value greater than 0 in the design display",
  MINI_CIP_DOSE_ERROR:
    "The value for mini-CIP chemical doses are currently 0. To continue, please adjust the at least one of the mini-CIP chemical doses amounts to a value greater than 0 in the mini-CIP display.",
  DIS_OXIDANT_CEB_DOSE_ERROR:
    "The value of Disinfectant oxidant Dose is 0, please adjust the dose amount to a value greater than 0 in the CEB display.",
  DIS_OXIDANT_DESIGN_VALUE_ERROR:
    "The disinfectant oxidant CEB design cycle interval is 0 , please update the value to a value greater than 0 in the design display",
};

/**
 * UF Module Constants
 *
 * MB 80 TR => 35
 * MB 70 WT => 36
 * MB 50 TR => 37
 * MB PRO 95 R => 57
 *
 * MB 60 TR S => 38
 * MB 40 TR S => 39
 * MB Pro 82 TRs => 58
 */
export const INGE_MODULE_TR_RANGES = {
  35: {
    TR1: { min: 4, max: 30, label: "Single side 2-row" },
    TR2: { min: 16, max: 30, label: "Single side 4-row" },
    TR4: { min: 16, max: 30, label: "Double side 4-row" },
  },
  36: {
    TR1: { min: 4, max: 30, label: "Single side 2-row" },
    TR2: { min: 16, max: 30, label: "Single side 4-row" },
    TR4: { min: 16, max: 30, label: "Double side 4-row" },
  },
  37: {
    TR1: { min: 4, max: 34, label: "Single side 2-row" },
    TR2: { min: 18, max: 34, label: "Single side 4-row" },
    TR4: { min: 18, max: 34, label: "Double side 4-row" },
  },
  57: {
    TR1: { min: 4, max: 26, label: "Single side 2-row" },
    TR2: { min: 14, max: 26, label: "Single side 4-row" },
    TR4: { min: 14, max: 26, label: "Double side 4-row" },
  },

  38: {
    TR1: { min: 4, max: 40, label: "Single side 2-row" },
    TR2: { min: 8, max: 40, label: "Single side 4-row" },
    TR4: { label: "Double side 4-row" },
  },
  39: {
    TR1: { min: 4, max: 40, label: "Single side 2-row" },
    TR2: { min: 8, max: 40, label: "Single side 4-row" },
    TR4: { label: "Double side 4-row" },
  },
  58: {
    TR1: { min: 4, max: 30, label: "Single side 2-row" },
    TR2: { min: 8, max: 30, label: "Single side 4-row" },
    TR4: { label: "Double side 4-row" },
  },
};

export const INGE_MODULE_TR_OPTIONS_RANGE = {
  35: { TR1: 30, TR2: 60 },
  36: { TR1: 40 },
  37: { TR1: 34, TR2: 68 },
  38: { TR1: 40 },
  39: { TR1: 40 },
  57: { TR1: 26, TR2: 52 },
  58: { TR1: 30 },
};

export const INGE_MODULE_TR_MULTIPLIER = {
  TR1: 2,
  TR2: 4,
  TR4: 4,
};

export const CHEMICAL_RANGES = {
  mineral: {
    ph: { DUPONT: { minValue: 2, maxValue: 7 }, INGE: { minValue: 1, maxValue: 7 } },
    mgL: { DUPONT: { minValue: 0, maxValue: 2500 }, INGE: { minValue: 0, maxValue: 2500 } },
  },
  alkali: {
    ph: { DUPONT: { minValue: 7, maxValue: 12 }, INGE: { minValue: 7, maxValue: 13 } },
    mgL: { DUPONT: { minValue: 0, maxValue: 2500 }, INGE: { minValue: 0, maxValue: 2500 } },
  },
  organic: {
    mgL: { DUPONT: { minValue: 0, maxValue: 20000 }, INGE: { minValue: 0, maxValue: 10000 } },
  },
  oxidant: {
    mgL: { DUPONT: { minValue: 0, maxValue: 2500 }, INGE: { minValue: 0, maxValue: 500 } },
  },
  disOxidant: {
    mgL: { INGE: { minValue: 0, maxValue: 500 } },
  },
  oxidant2: {
    mgL: { INGE: { minValue: 0, maxValue: 10 } },
  },
};

// key remains same for both inge and dupont
export const CHEMICAL_RANGES_KEY_MAPPING = {
  mineral: {
    [UF_CHEMICAL_SCREENS.CIP]: "CIP Mineral Acid Concentration",
    [UF_CHEMICAL_SCREENS.CEB]: "CEB Mineral Acid Concentration",
    [UF_CHEMICAL_SCREENS.MINI_CIP]: "CIP Mineral Acid Concentration",
  },
  organic: {
    [UF_CHEMICAL_SCREENS.CIP]: "CIP Organic Acid Concentration",
    [UF_CHEMICAL_SCREENS.CEB]: "CEB Organic Acid Concentration",
    [UF_CHEMICAL_SCREENS.MINI_CIP]: "mCIP Organic Acid Concentration",
  },
  alkali: {
    [UF_CHEMICAL_SCREENS.CIP]: "CIP Alkali Concentration",
    [UF_CHEMICAL_SCREENS.CEB]: "CEB Alkali Concentration",
    [UF_CHEMICAL_SCREENS.MINI_CIP]: "CIP Alkali Concentration",
  },
  oxidant: {
    [UF_CHEMICAL_SCREENS.CIP]: "CIP Oxidant Concentration",
    [UF_CHEMICAL_SCREENS.CEB]: "CEB Oxidant Concentration",
    [UF_CHEMICAL_SCREENS.MINI_CIP]: "CIP Oxidant Concentration",
  },
  disOxidant: {
    [UF_CHEMICAL_SCREENS.CEB]: "Dis. CEB Oxidant",
  },
  oxidant2: {
    [UF_CHEMICAL_SCREENS.CIP]: "CIP SLS",
    [UF_CHEMICAL_SCREENS.MINI_CIP]: "CIP SLS",
  },
};

/**
 * Keeping constants on basis of company code
 */
export const TYPICAL_WAIT_DURATION = {
  1: "typicalWaitDuration_Dupont",
  2: "typicalWaitDuration_Inge",
  5: "Typical Wait Duration_Memcor",
};

export const TYPICAL_PUMP_RAMP = {
  1: "typicalPumpRamp_Dupont",
  2: "typicalPumpRamp_Inge",
  5: "Typical Pump Ramp_Memcor",
};

export const CHEMICAL_TYPES = {
  mineral: "mineral",
  alkali: "alkali",
  organic: "organic",
  oxidant: "oxidant",
  oxidant2: "oxidant2",
  disOxidant: "disOxidant",
};

export const UF_CHEMICAL_FIELDS = {
  mineral: "Mineral Acid",
  organic: "Organic Acid",
  alkali: "Alkali",
  oxidant: "Oxidant",
  oxidant2: "Surfactant",
  disOxidant: "Oxidant",
};

export const UF_CHEMICAL_FIELDS_MAPPING = {
  mineral: "Acid",
  organic: "Organic Acid",
  alkali: "Base",
  oxidant: "Oxidant",
  oxidant2: "Surfactant",
  disOxidant: "Oxidant",
  coagulant: "Coagulant",
};

export const UF_TECH_ID = {
  dupontId: 1,
  ingeId: 2,
};

export const WATER_TYPES_LIMITS = {
  2: { turbidityLimit: 20, tssLimit: 40, tocLimit: 5 }, //"Softened
  3: { turbidityLimit: 20, tssLimit: 40, tocLimit: 5 }, //"Municipal"
  4: { turbidityLimit: 20, tssLimit: 40, tocLimit: 5 }, //"Well"
  5: { turbidityLimit: 20, tssLimit: 40, tocLimit: 8 }, //"Surface Water
  6: { turbidityLimit: 20, tssLimit: 40, tocLimit: 9 }, //"Sea Water"
  7: { turbidityLimit: 50, tssLimit: 100, tocLimit: 30 }, //"Waste Water"
};

/** The uFCIPWaterTypeID is set to 1, as we are also setting uFCEBWaterTypeID = 1 and uFMiniCIPWaterTypeID = 1 and uFCIPWaterTypeID = 1
 * while fetching UFDetails and storing them in the Redux store(Their was id mismatch betweend constant and
 * setting value after fetching data from UF details).
 * */
export const UF_WATER_SOURCES = [
  {
    uFCIPWaterTypeID: 1,
    cIPWaterTypeName: "UF Filtrate",
  },
];

export const technologyIDMapping = {
  UF: 1,
  IXD: 5,
  IXMB: 9,
};

export const UFCongurationTableHeader = [
  { field: "selectConfiguration", header: "Select Configuration" },
  { field: "onlineUnits", header: "Online Units" },
  { field: "standbyUnits", header: "Standby Units" },
  { field: "totalUnits", header: "Total Units" },
  { field: "maxOfflineBWCEB", header: "Max Offline BW/CEB" },
  { field: "modulesPerRack", header: "Modules/Rack" },
  { field: "racksPerUnit", header: "Racks/Unit" },
  { field: "modulesPerUnit", header: "Modules/Unit" },
  { field: "onlineModules", header: "Online Modules" },
  { field: "totalModules", header: "Total Modules" },
];

import { createSlice } from "@reduxjs/toolkit";

import { HomeSideNavIds } from "@features/home/HomeSideNav";

const initialState = {
  //TODO: Rename to activeSideMenu
  activeMenu: HomeSideNavIds.home,
  breadcrumbData: null,
};
export const SideMenuSlice = createSlice({
  //TODO: Rename to sideMenu or homeSideMenu?
  name: "leftpanel",
  initialState,
  reducers: {
    updateActiveMenu: (state, action) => {
      state.activeMenu = action.payload;
    },
    //TODO: Rename to setBreadcrumbData
    setSideMenu: (state, action) => ({ ...state, ...action.payload }),
  },
});

export const { updateActiveMenu, setSideMenu } = SideMenuSlice.actions;

export default SideMenuSlice.reducer;

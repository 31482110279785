import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { convertUptoDigits } from "@utils/appUtils";

import BoldTextElement from "../UFDiagramsUtils/BoldTextElement";
import TextElement from "../UFDiagramsUtils/TextElement";

const UFDiagramStrainerDetails = ({ isInge, isCIP }) => {
  const { data } = useSelector(state => state.UFStore);

  const xyPosition = useMemo(
    () => ({
      x: 1650,
      y: !isInge || isCIP ? 4450 : 4050,
    }),
    [isInge, isCIP],
  );

  const strainerData = useMemo(() => {
    const { strainerSize, strainerRecovery } = data;
    return {
      size: convertUptoDigits(strainerSize, 0),
      recovery: convertUptoDigits(strainerRecovery, 1),
    };
  }, [data]);

  return (
    <>
      <BoldTextElement>
        <tspan {...xyPosition}>Strainer</tspan>
      </BoldTextElement>
      <TextElement fill='#454545'>
        <tspan x={xyPosition.x} y={xyPosition.y + 140}>
          {`${strainerData.size} µm`}
        </tspan>
        <tspan x={xyPosition.x} y={xyPosition.y + 280}>
          {`${strainerData.recovery} % Recovery`}
        </tspan>
      </TextElement>
    </>
  );
};

export default UFDiagramStrainerDetails;

import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import useUnitConversion from "@hooks/useUnitConversion";

import { calculateSum, convertUptoDigits } from "@utils/appUtils";

import { UF_FEED_PUMP_MAX } from "@features/feedwater/uf/constants/UFConstants";

import BoldTextElement from "../UFDiagramsUtils/BoldTextElement";
import TextElement from "../UFDiagramsUtils/TextElement";
import { getPumpValues } from "../UFDiagramsUtils/UFDiagramHelper";

const PRESSURE_METRIC_UNIT = "bar";

const UFDiagramFeedPumpDetails = ({ isInge, isCIP }) => {
  const { unitConversionByName } = useUnitConversion();
  const { ufCompany, calcEngineData } = useSelector(state => state.UFStore);
  const { unitConfig } = useSelector(state => state.projectInfo?.projectConfig);
  const [volumData, setVolumData] = useState({});

  /**
   * Calculates the maximum feed pump pressure.
   *
   * This function uses the useMemo hook to memoize the result, recalculating only when
   * calcEngineData or ufCompany changes. It converts P_Filtrate and Delta_P_Piping_T_min
   * to numbers, defaulting to 0 if they are undefined or null, and sums them with a
   * predefined maximum TMP value for the given company.
   *
   * @returns {number} The calculated maximum feed pump pressure.
   */
  const feedPumpMax = useMemo(() => {
    const { p_Filtrate = 0, delta_P_Piping_T_min = 0 } = calcEngineData;
    const maxTMP = UF_FEED_PUMP_MAX[ufCompany];
    const sum = calculateSum([p_Filtrate, delta_P_Piping_T_min, maxTMP]);
    return convertUptoDigits(sum, 2);
  }, [calcEngineData, ufCompany]);

  useEffect(() => {
    let value = feedPumpMax;
    if (value && unitConfig.selectedUnits[3] !== PRESSURE_METRIC_UNIT) {
      value = unitConversionByName(value, unitConfig.selectedUnits[3], PRESSURE_METRIC_UNIT);
    }
    const data = getPumpValues(unitConfig, calcEngineData, "flow_Feed_Pump_max", value, unitConversionByName);
    setVolumData(data);
  }, [unitConfig.selectedUnits, calcEngineData, getPumpValues]);

  const xyPosition = useMemo(
    () => (isInge ? (isCIP ? { x: 550, y: 2815 } : { x: 550, y: 2400 }) : { x: 600, y: 2825 }),
    [isInge],
  );

  return (
    <>
      <BoldTextElement {...xyPosition}>Feed Pump</BoldTextElement>
      <TextElement fill='#454545'>
        <tspan x={xyPosition.x} y={xyPosition.y + 140}>
          Max {volumData.value}
        </tspan>
        <tspan x={xyPosition.x} y={xyPosition.y + 280}>
          {volumData.pressure}
        </tspan>
      </TextElement>
    </>
  );
};

export default UFDiagramFeedPumpDetails;

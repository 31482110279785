import { OpenProjectFilterLabels, projectInfoModalStrings } from "@utils/StringConstants";

export const SESSION_TIMEOUT_MS = 1800000;
export const MIN_PROJECT_NAME_LENGTH = 3;
export const MAX_PROJECT_NAME_LENGTH = 200;
export const PROJECT_NAME_MAX_LENGTH = 20; //Length of project name
export const TOAST_DEFAULT_DURATION = 3000; //3 seconds
export const TOAST_DEFAULT_DURATION_ERROR = 5000; //5 seconds
export const DATE_FORMAT = "MMM DD, YYYY, hh:mm:ss A z";
export const COUNTRY_STATE_ID = 0;
export const CHARGE_BALANCE_THRESHOLD = 0.000099;
export const MAX_CURRENCY_EXCHANGE_RATE = 1000000;

export const TECHNOLOGIES = {
  UF: "UF",
  IX: "IX",
  RO: "RO",
  IXD: "IXD",
  IXN: "IXN",
  IXB: "IXB",
  IXSD: "IXSD",
  IXMB: "IXMB",
  IXOS: "IXOS",
  CCRO: "CCRO",
  ROSC: "ROSC",
  IXCP: "IXCP",
};

export const IX_TECHNOLOGIES = [TECHNOLOGIES.IX, TECHNOLOGIES.IXD, TECHNOLOGIES.IXMB];

export const PROJECT_VIEWS = {
  DESIGN: "System Design",
  FEED_SETUP: "Feed Setup",
  ...TECHNOLOGIES,
  REPORT: "Report",
};

export const CHEMICALS_SYMBOL_MAPPING = {
  "₀": "0",
  "₁": "1",
  "₂": "2",
  "₃": "3",
  "₄": "4",
  "₅": "5",
  "₆": "6",
  "₇": "7",
  "₈": "8",
  "₉": "9",
};

// As per bug 141619 hard limit is set for feed setup temperature as per the technology selected.
export const TEMPERARTURE_RANGES = {
  "°C": {
    soft: {
      min: 1,
      max: {
        UF: 40.0,
        IXD: 60.0,
        IXMB: 60.0,
      },
    },
    hard: {
      min: 0,
      max: {
        UF: 40.0,
        IXD: 60.0,
        IXMB: 60.0,
        IXOS: 60.0,
        IXN: 60.0,
        IXB: 60.0,
        IXCP: 60.0,
        RO: 80.0,
        IXSD: 100.0,
      },
    },
  },
  "°F": {
    soft: {
      min: 33.8,
      max: {
        UF: 104,
        IXD: 140,
        IXMB: 140,
      },
    },
    hard: {
      min: 0,
      max: {
        UF: 104,
        IXD: 140,
        IXMB: 140,
        IXOS: 140,
        IXN: 140,
        IXB: 140,
        IXCP: 140,
        RO: 176,
        IXSD: 212,
      },
    },
  },
};
export const FLOW_METRIC_UNIT = "m³/h";

export const DEFAULT_RECOVERY_VALUES = {
  UF: 99.12,
  IXD: 97,
  IXMB: 97,
  DEFAULT: 100,
};

export const STATUS_CODE = {
  SUCCESS: 200,
  NO_CONTENT: 204,
};

export const PERCENTAGE_RANGE = {
  min: 0,
  max: 100,
};

export const CustomModalButtons = {
  CANCEL: "modal-cancel-btn",
  CONFIRM: "modal-confirm-btn",
  CLOSE: "modal-close-icon",
  DELETE: "modal-delete-btn",
};

export const DEFAULT_STATE = { stateName: projectInfoModalStrings.defaultState };
export const DEFAULT_COUNTRY = { countryName: projectInfoModalStrings.defaultCountry };
export const DEFAULT_MARKET_SEGMENT = { segmentName: projectInfoModalStrings.defaultMarketSegment };
export const DEFAULT_MARKET_SEGMENT_FILTER = { segmentName: projectInfoModalStrings.defaultMarketSegmentFilter };

export const EXPORT_EXTENSION = ".dwpro";

export const FILTER_OPTIONS_OPEN_PROJECT = [
  {
    label: OpenProjectFilterLabels.equal,
    value: "equal",
  },

  {
    label: OpenProjectFilterLabels.doesnotequal,
    value: "doesnotequal",
  },
  {
    label: OpenProjectFilterLabels.beginswith,
    value: "beginswith",
  },
  {
    label: OpenProjectFilterLabels.endswith,
    value: "endswith",
  },
  {
    label: OpenProjectFilterLabels.contains,
    value: "contains",
  },
  {
    label: OpenProjectFilterLabels.doesnotcontains,
    value: "doesnotcontains",
  },
];

export const FILE_ACCESS_ACTIONS = {
  NEW_PROJECT: "openNewProject",
  OPEN_PROJECT: "showOpenProject",
  RECENT_PROJECT: "recentProjectClick",
  NAVIGATE_HOME: "navigateHome",
};

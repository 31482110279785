import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import useUnitConversion from "@hooks/useUnitConversion";

import BoldTextElement from "../UFDiagramsUtils/BoldTextElement";
import TextElement from "../UFDiagramsUtils/TextElement";
import { formatFlowValue, formatPressureValue } from "../UFDiagramsUtils/UFDiagramHelper";

const FLOW_METRIC_UNIT = "m³/h";
const PRESSURE_METRIC_UNIT = "bar";

const UFDiagramBWCEBPumpDetails = ({ isInge, isCIP }) => {
  const { unitConversionByName } = useUnitConversion();
  const { calcEngineData } = useSelector(state => state.UFStore);
  const { unitConfig } = useSelector(state => state.projectInfo?.projectConfig);

  const [pumpData, setPumpData] = useState({});
  const [maxPressure, setMaxPressure] = useState("");

  const getFiltrate = filtrate => {
    const selectedFlowUnit = unitConfig.selectedUnits[1];
    let value = (calcEngineData && calcEngineData[filtrate]) || 0;

    if (value && selectedFlowUnit !== FLOW_METRIC_UNIT) {
      value = unitConversionByName(value, selectedFlowUnit, FLOW_METRIC_UNIT);
    }
    return formatFlowValue(value, selectedFlowUnit);
  };

  useEffect(() => {
    const data = {
      bw: `BW ${getFiltrate("flow_BW_Pump")}`,
      ceb: `CEB ${getFiltrate("flow_CEB_Pump")}`,
    };
    setPumpData(data);

    const pressureUnit = unitConfig.selectedUnits[3];
    let pressureValue = 2.5;
    if (PRESSURE_METRIC_UNIT !== pressureUnit) {
      pressureValue = unitConversionByName(pressureValue, pressureUnit, PRESSURE_METRIC_UNIT);
    }
    pressureValue = formatPressureValue(pressureValue, pressureUnit);
    setMaxPressure(pressureValue);
  }, [unitConfig.selectedUnits, calcEngineData]);

  const xyPosition = useMemo(
    () => (isInge ? (isCIP ? { x: 5650, y: 3250 } : { x: 5650, y: 2850 }) : { x: 6000, y: 3250 }),
    [isInge, isCIP],
  );

  return (
    <>
      <BoldTextElement>
        <tspan {...xyPosition}>Backwash Pump</tspan>
      </BoldTextElement>
      <TextElement fill='#454545'>
        <tspan x={xyPosition.x} y={xyPosition.y + 140}>
          {pumpData.bw}
        </tspan>
        <tspan x={xyPosition.x} y={xyPosition.y + 280}>
          {pumpData.ceb}
        </tspan>
        <tspan x={xyPosition.x} y={xyPosition.y + 420}>
          {maxPressure}
        </tspan>
      </TextElement>
    </>
  );
};

export default UFDiagramBWCEBPumpDetails;

import React from "react";

const HomeIcon = () => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g id='Home' clipPath='url(#clip0_1159_21285)'>
      <path
        id='Vector'
        d='M13.7899 6.88969L7.75241 0.855313L7.34772 0.450625C7.25551 0.359026 7.13082 0.307617 7.00085 0.307617C6.87087 0.307617 6.74618 0.359026 6.65397 0.450625L0.211783 6.88969C0.117301 6.9838 0.0426296 7.09589 -0.00782253 7.21934C-0.0582747 7.34279 -0.0834854 7.47509 -0.0819665 7.60844C-0.0757165 8.15844 0.382096 8.5975 0.932096 8.5975H1.59616V13.6866H12.4055V8.5975H13.0837C13.3508 8.5975 13.6024 8.49281 13.7915 8.30375C13.8846 8.21096 13.9583 8.10062 14.0085 7.97912C14.0586 7.85762 14.0842 7.72738 14.0837 7.59594C14.0837 7.33031 13.979 7.07875 13.7899 6.88969ZM7.87585 12.5616H6.12585V9.37406H7.87585V12.5616ZM11.2805 7.4725V12.5616H8.87585V8.99906C8.87585 8.65375 8.59616 8.37406 8.25085 8.37406H5.75085C5.40553 8.37406 5.12585 8.65375 5.12585 8.99906V12.5616H2.72116V7.4725H1.22116L7.00241 1.69594L7.36335 2.05688L12.7821 7.4725H11.2805Z'
        fill='black'
        fillOpacity='0.85'
      />
    </g>
    <defs>
      <clipPath id='clip0_1159_21285'>
        <rect width='14' height='14' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default HomeIcon;

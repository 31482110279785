import React from "react";

const SearchProjectEmptyState = () => (
  <>
    <svg width='154' height='166' viewBox='0 0 154 166' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle opacity='0.3' cx='80' cy='74' r='74' fill='url(#paint0_linear_1416_36523)' />
      <g filter='url(#filter0_ddd_1416_36523)'>
        <path
          d='M36.388 50.5991C35.3992 46.3915 38.0086 42.1789 42.2163 41.1901L78.7224 32.6113C81.1204 32.0478 83.6438 32.6462 85.5334 34.2265L92.5375 40.084L100.714 45.5618C102.358 46.6633 103.524 48.3468 103.976 50.2733L115.431 99.0191C116.42 103.227 113.811 107.439 109.603 108.428L60.7166 119.916C56.509 120.905 52.2964 118.296 51.3076 114.088L36.388 50.5991Z'
          fill='url(#paint1_linear_1416_36523)'
        />
        <path d='M52.375 61.8047L91.3143 52.6541' stroke='white' strokeWidth='6' strokeLinecap='round' />
        <path d='M55.1211 73.4863L75.5642 68.6823' stroke='white' strokeWidth='6' strokeLinecap='round' />
        <path
          d='M82.6189 47.7749L79.4665 34.3603C79.0759 32.698 80.853 31.368 82.3376 32.2115L92.5317 38.0028C93.7003 38.6666 94.7823 39.4725 95.7532 40.4019L102.925 47.2674C104.046 48.3409 103.53 50.2302 102.019 50.5854L91.2438 53.1175C87.3868 54.0239 83.5253 51.6319 82.6189 47.7749Z'
          fill='#E1E1E1'
        />
      </g>
      <path
        d='M149.802 132.697L128.377 111.272C131.701 106.974 133.5 101.719 133.5 96.1913C133.5 89.5748 130.918 83.3709 126.248 78.6931C121.579 74.0154 115.358 71.4414 108.75 71.4414C102.142 71.4414 95.9212 74.0236 91.2517 78.6931C86.574 83.3626 84 89.5748 84 96.1913C84 102.8 86.5822 109.02 91.2517 113.689C95.9212 118.367 102.133 120.941 108.75 120.941C114.277 120.941 119.524 119.143 123.823 115.826L145.248 137.243C145.311 137.306 145.385 137.356 145.467 137.39C145.549 137.424 145.637 137.441 145.726 137.441C145.815 137.441 145.903 137.424 145.985 137.39C146.067 137.356 146.142 137.306 146.205 137.243L149.802 133.654C149.865 133.592 149.914 133.517 149.948 133.435C149.982 133.353 150 133.265 150 133.176C150 133.087 149.982 132.999 149.948 132.917C149.914 132.835 149.865 132.76 149.802 132.697ZM121.818 109.259C118.32 112.749 113.683 114.671 108.75 114.671C103.816 114.671 99.1799 112.749 95.6819 109.259C92.1922 105.761 90.27 101.125 90.27 96.1913C90.27 91.2578 92.1922 86.6131 95.6819 83.1234C99.1799 79.6336 103.816 77.7114 108.75 77.7114C113.683 77.7114 118.328 79.6254 121.818 83.1234C125.308 86.6213 127.23 91.2578 127.23 96.1913C127.23 101.125 125.308 105.77 121.818 109.259Z'
        fill='#969696'
      />
      <g filter='url(#filter1_f_1416_36523)'>
        <circle cx='108.5' cy='96.5' r='18.5' fill='url(#paint2_linear_1416_36523)' fillOpacity='0.5' />
      </g>
      <defs>
        <filter
          id='filter0_ddd_1416_36523'
          x='0.179688'
          y='4.95117'
          width='151.461'
          height='160.174'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology radius='8' operator='dilate' in='SourceAlpha' result='effect1_dropShadow_1416_36523' />
          <feOffset dy='9' />
          <feGaussianBlur stdDeviation='14' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1416_36523' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='6' />
          <feGaussianBlur stdDeviation='8' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0' />
          <feBlend mode='normal' in2='effect1_dropShadow_1416_36523' result='effect2_dropShadow_1416_36523' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology radius='4' operator='erode' in='SourceAlpha' result='effect3_dropShadow_1416_36523' />
          <feOffset dy='3' />
          <feGaussianBlur stdDeviation='3' />
          <feColorMatrix type='matrix' values='0 0 0 0 0.0705882 0 0 0 0 0.513726 0 0 0 0 0.439216 0 0 0 0.12 0' />
          <feBlend mode='normal' in2='effect2_dropShadow_1416_36523' result='effect3_dropShadow_1416_36523' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect3_dropShadow_1416_36523' result='shape' />
        </filter>
        <filter
          id='filter1_f_1416_36523'
          x='86'
          y='74'
          width='45'
          height='45'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='2' result='effect1_foregroundBlur_1416_36523' />
        </filter>
        <linearGradient id='paint0_linear_1416_36523' x1='80' y1='0' x2='80' y2='148' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#6DD8CD' />
          <stop offset='1' stopColor='#E2F7F5' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_1416_36523'
          x1='66.6595'
          y1='35.4461'
          x2='85.1598'
          y2='114.172'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F8F8F8' />
          <stop offset='1' stopColor='#E1E1E1' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_1416_36523'
          x1='108.5'
          y1='78'
          x2='108.5'
          y2='115'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F8F8F8' />
          <stop offset='1' stopColor='white' />
        </linearGradient>
      </defs>
    </svg>
  </>
);
export default SearchProjectEmptyState;

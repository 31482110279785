import React, { useMemo } from "react";

import { combineClassNames } from "@utils/appUtils";

import WPHeading from "@components/WPHeading";

const HomeSideNavItem = ({ menuOption, selectedOption, onClick, isFolderOpened }) => {
  const { id, icon, label } = menuOption;
  const isActive = useMemo(() => selectedOption === id, [selectedOption, id]);

  const handleClick = () => onClick(menuOption.id);

  return (
    <li
      id={id}
      className={combineClassNames("side-nav-option", isActive ? "side-nav-menu-active" : "")}
      onClick={handleClick}
    >
      {React.cloneElement(icon, { opened: isFolderOpened })}
      <WPHeading secondary size='small' type='default'>
        {label}
      </WPHeading>
    </li>
  );
};

export default HomeSideNavItem;

import { createSlice } from "@reduxjs/toolkit";

import { HomeSortOption } from "./HomeFilters/constants/HomeFilters.constant";

const filterConfigInitialState = {
  technology: [],
  marketSegment: null,
};

const sortConfigInitialState = {
  key: "modifiedDate",
  order: "desc",
};

const initialState = {
  data: [],
  loader: false,
  sbTechnology: [], // todo: move this to globalData ?
  flag: HomeSortOption.modifiedDate, // deprecate this with new sort dropdown change
  searchValue: "",
  sortConfig: sortConfigInitialState,
  filterConfig: filterConfigInitialState,
};

export const CardListSlice = createSlice({
  name: "cardlist",
  initialState,
  reducers: {
    updateProjectsList: (state, action) => {
      state.data = action.payload;
    },
    updateLoader: (state, action) => {
      state.loader = action.payload;
    },
    updateSubTechnology: (state, action) => {
      state.sbTechnology = action.payload;
    },
    updateFlag: (state, action) => {
      state.flag = action.payload;
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    updateSortConfig: (state, action) => {
      state.sortConfig = action.payload;
    },
    updateFilterConfig: (state, { payload }) => {
      state.filterConfig = { ...state.filterConfig, ...payload };
    },
    resetFilterConfig: state => {
      state.filterConfig = filterConfigInitialState;
    },
    resetSorConfig: state => {
      state.sortConfig = sortConfigInitialState;
    },
  },
});

export const {
  updateProjectsList,
  updateLoader,
  updateSubTechnology,
  updateFlag,
  setSearchValue,
  updateSortConfig,
  updateFilterConfig,
  resetFilterConfig,
  resetSorConfig,
} = CardListSlice.actions;

export default CardListSlice.reducer;

import React from "react";

const LinkedInIcon = () => (
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M24.625 6.625H7.375C6.96016 6.625 6.625 6.96016 6.625 7.375V24.625C6.625 25.0398 6.96016 25.375 7.375 25.375H24.625C25.0398 25.375 25.375 25.0398 25.375 24.625V7.375C25.375 6.96016 25.0398 6.625 24.625 6.625ZM12.1867 22.6023H9.40469V13.6539H12.1867V22.6023ZM10.7969 12.4305C10.478 12.4305 10.1662 12.3359 9.90102 12.1587C9.63584 11.9815 9.42917 11.7297 9.30712 11.435C9.18507 11.1404 9.15314 10.8162 9.21536 10.5034C9.27758 10.1906 9.43115 9.90327 9.65667 9.67776C9.88218 9.45225 10.1695 9.29867 10.4823 9.23645C10.7951 9.17423 11.1193 9.20617 11.414 9.32821C11.7086 9.45026 11.9604 9.65694 12.1376 9.92211C12.3148 10.1873 12.4094 10.499 12.4094 10.818C12.407 11.7086 11.6852 12.4305 10.7969 12.4305ZM22.6023 22.6023H19.8227V18.25C19.8227 17.2117 19.8039 15.8781 18.3766 15.8781C16.9305 15.8781 16.7078 17.0078 16.7078 18.175V22.6023H13.9305V13.6539H16.5977V14.8773H16.6352C17.0055 14.1742 17.9125 13.4312 19.2672 13.4312C22.0844 13.4312 22.6023 15.2852 22.6023 17.6945V22.6023Z'
      fill='#6DD8CD'
    />
  </svg>
);

export default LinkedInIcon;

import React from "react";

const YoutubeIcon = () => (
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M25.7795 10.9398C25.6599 10.4946 25.4256 10.0886 25.0998 9.76243C24.774 9.43625 24.3683 9.20136 23.9232 9.08125C22.285 8.64063 15.7178 8.64062 15.7178 8.64062C15.7178 8.64062 9.15059 8.64062 7.5123 9.07891C7.06704 9.19862 6.66111 9.43339 6.33529 9.75962C6.00946 10.0859 5.77521 10.4921 5.65605 10.9375C5.21777 12.5781 5.21777 16 5.21777 16C5.21777 16 5.21777 19.4219 5.65605 21.0602C5.89746 21.9648 6.60996 22.6773 7.5123 22.9188C9.15059 23.3594 15.7178 23.3594 15.7178 23.3594C15.7178 23.3594 22.285 23.3594 23.9232 22.9188C24.8279 22.6773 25.5381 21.9648 25.7795 21.0602C26.2178 19.4219 26.2178 16 26.2178 16C26.2178 16 26.2178 12.5781 25.7795 10.9398ZM13.6318 19.1406V12.8594L19.0693 15.9766L13.6318 19.1406Z'
      fill='#6DD8CD'
    />
  </svg>
);

export default YoutubeIcon;
